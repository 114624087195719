.customisable-promo-boxes {
	margin: 50px 0;

	@media screen and (min-width: 900px) {
		margin: 70px 0;
	}

	@media screen and (min-width: 1080px) {
		margin: 90px 0;
	}

	@media screen and (min-width: 1200px) {
		margin: 105px 0;
	}

	.row {
		margin: 0 -15px !important;

		.col-md-4 {
			padding: 10px;

			@media screen and (min-width: 1080px) {
				padding: 15px;
			}
		}
	}

	&__heading-container {
		margin-bottom: 30px;

		h2 {
			display: inline-block;
		}

		p {
			display: inline-block;
			font-size: 14px;
		}
	}

	.col-md-4 {
		height: calc((100vw - 32px));

		@media screen and (min-width: 700px) {
			height: calc((100vw - 32px) / 3);
		}

		@media screen and (min-width: 1400px) {
			max-height: 406px;
		}

		.customisable-promo-boxes__box {
			height: 100%;
		}
	}

	.col-md-6 {
		padding: 10px 15px;
		height: calc((100vw - 32px));

		@media screen and (min-width: 700px) {
			height: calc((100vw - 32px) / 2);
		}

		@media screen and (min-width: 1400px) {
			max-height: 635px;
		}

		.customisable-promo-boxes__box {
			height: 100%;
		}
	}

	&__box {
		background-position: center;
		background-size: cover;
		cursor: pointer;
		height: 200px;
		overflow: hidden;
		position: relative;

		&-image {
			background-position: center;
			background-size: cover;
			position: absolute;
			height: 120%;
			width: 120%;
			top: -10%;
			left: -10%;
			transition-property: all;
			transition-duration: 0.8s; 
			transition-timing-function: ease-in-out;
		}

		@media screen and (min-width: 900px) {
			height: 290px;
		}

		@media screen and (min-width: 1080px) {
			height: 330px;
		}

		&:hover {
			.customisable-promo-boxes__box--overlay {
				opacity: 1;
			}

			.customisable-promo-boxes__box-image {
				transform: scale(1.2); 
			  	rotate: 20deg;
			}

			a {
				background-color: $text_colour;
			}
		}

		&--content-holder {
			bottom: 20px;
			left: 0;
			padding: 0 20px;
			position: absolute;
			z-index: 10;

			@media screen and (min-width: 900px) {
				bottom: 30px;
				padding: 0 30px;
			}

			h4 {
				color: $white;
				margin-bottom: 5px;
				letter-spacing: 0px;
				font-weight: 400;
				text-transform: uppercase;
			}

			a {
				margin-top: 10px;

				&:hover {
					&:after {
						transform: translate(15px, 2px);
					}
				}
			}
		}

		&--overlay {
			background: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.7));
			bottom: 0;
			height: 60%;
			left: 0;
			opacity: 0.7;
			position: absolute;
			transition: 0.3s;
			width: 100%;
		}
	}
}
