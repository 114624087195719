.customisable-hbp {
	margin: 50px 0 50px;

	@media screen and (min-width: 1080px) {
		margin: 70px 0 60px;
	}

	@media screen and (min-width: 1200px) {
		margin: 85px 0 70px;
	}

	&--grey {
		background-color: #f5f3f2;
	}

	&__bullet-points {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	&__bullet-point {
		width: 100%;
		margin-bottom: 20px;
		padding-left: 15px;
		padding-right: 15px;

		@media screen and (min-width: 500px) {
			width: 50%;
			margin-bottom: 27px;
		}

		@media screen and (min-width: 900px) {
			width: 33.333%;
		}

		@media screen and (min-width: 1200px) {
			margin-bottom: 35px;
		}

		p {
			color: $text_colour;
			font-family: $secondary_font;
			font-style: normal;
			font-weight: 400;
			font-size: 20px;
			text-align: center !important;
			max-width: 300px;
			margin: 0 auto;

			@media only screen and (min-width: 900px) {
				font-size: 24px;
			}
			@media only screen and (min-width: 1200px) {
				font-size: 26px;
			}
		}
	}
}
