.customisable-banner {
	&__container {
		&--standard-width {
			max-width: $grid_width;
			padding: 0 30px;
			margin: 50px auto;

			@mixin screen-size-900 {
				margin: 70px auto;
			}

			@media screen and (min-width: 1080px) {
				margin: 90px auto;
			}

			@media screen and (min-width: 1200px) {
				margin: 105px auto;
			}
		}
	}

	&__image {
		width: 100%;
		height: 100%;
		display: flex;
		position: relative;
		width: 100%;
		padding-top: 56.25%; /* 16:9 aspect ratio (9 / 16 * 100) */
		overflow: hidden;
		img {
			position: absolute;
			top: 0;
			left: 0;
 			width: 100%;
			height: 100%;
			object-fit: cover;
 			transition: transform 0.5s ease-in-out;
		}

		&-caption {
			color: #402431;
			font-family: $primary_font;
			font-style: normal;
			font-weight: 400;
			line-height: 28px; /* 155.556% */
			font-size: 18px;
			margin-top: 20px;

			p {
				transition: transform 0.5s ease-in-out;
			}

			@mixin screen-size-900 {
				font-size: 20px;
				margin-top: 16px;
			}

			.customisable-banner__container--full-width & {
				padding-left: 30px;
			}
		}
	}

	&__link {
		text-decoration: none;
		&:hover {
			.customisable-banner__image {
				img {
					transform: scale(1.1);
				}

				&-caption {
					p {
						color: $text_colour !important;
					}
				}
			}
		}
	}
}
