.customisable-enquiry-form {
	margin: 50px 0;

	@mixin screen-size-900 {
		margin: 70px 0;
	}

	@media screen and (min-width: 1080px) {
		margin: 90px 0;
	}

	.customisable-h2 {
		text-align: center;
	}

	&__form {
		max-width: 900px;
		margin: 30px auto 0;

		@mixin screen-size-900 {
			margin-top: 35px;
		}

		@media screen and (min-width: 1080px) {
			margin-top: 40px;
		}

		@media screen and (min-width: 1200px) {
			margin-top: 50px;
		}

		form {
			padding-top: 20px;

			&.feedback-form {
				display: block;
				width: 100%;
			}

			.row {
				margin: 0 -20px;

				.col-sm-6,
				.col-xs-12 {
					padding: 10px;
				}
			}

			label {
				color: $text_colour;
				display: block;
				font-family: $secondary_font;
				font-size: 16px;
				font-weight: 400;
				line-height: 18px;
				margin-bottom: 20px;
				text-transform: uppercase;
			}

			input[type='text'],
			input[type='email'],
			select {
				border: 1px solid $text_colour;
				background: transparent !important;
				border-radius: 0px;
				font-family: $primary_font;
				font-size: 16px;
				height: 55px;
				margin-bottom: 20px;
				padding-left: 20px;
				width: 100%;

				&::placeholder {
					color: $text_colour;
				}
			}

			textarea {
				border: 1px solid $text_colour;
				background: transparent;
				font-family: $primary_font;
				margin-bottom: 20px;
				font-size: 16px;
				min-height: 160px;
				padding: 15px 0 0 20px;
				width: 100%;
			}

			.c__button {
				float: right;
			}

			.checkbox-holder {
				input[type='checkbox'] {
					border: 1px solid $text_colour;
					height: 25px;
					position: absolute;
					width: 25px;
					-webkit-appearance: none;

					&:checked {
						background-image: url(../../images/graphics/tick.svg);
						background-repeat: no-repeat;
						background-position: center;
						background-size: 60%;
					}
				}

				label {
					color: $text_colour;
					font-size: 14px;
					line-height: 20px;
					padding-left: 40px;
				}
			}
		}

		.w-form-done,
		.w-form-fail {
			display: none;
		}

		.dd-enquiry-form {
			&__input,
			&__datepicker,
			&__textarea,
			&__select {
				color: $text_colour;
			}

			&__submit {
				background-color: $text_colour;
				width: 200px;
				margin: 0 auto;
				display: block;
				color: $white;
				padding: 18px 32px;
				font-size: 16px;

				&:hover {
					background-color: lighten($text_colour, 10%);
					color: $white;
				}
			}
		}
	}
}
