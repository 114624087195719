.customisable-tib {
	position: relative;
	background-color: $grey1;
	padding: 50px 0;

	@media only screen and (min-width: 700px) {
		padding: 50px 0 80px;
	}

	@media screen and (min-width: 1080px) {
		padding: 70px 0 90px;
	}

	@media screen and (min-width: 1200px) {
		padding: 85px 0 100px;
	}

	&__bg-img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	> .container {
		position: relative;
	}

	&--alt {
		background-color: $grey2;
	}

	&__media {
		height: 400px;
		width: 100%;

		@media only screen and (min-width: 700px) {
			width: auto;
		}

		@media screen and (min-width: 900px) {
			height: 500px;
		}
	}

	&__copy {
		color: $text_colour;
		font-family: $primary_font;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 200%;
		margin-top: 20px;

		@media screen and (min-width: 900px) {
			font-size: 17px;
			margin-top: 28px;
		}

		@media screen and (min-width: 1200px) {
			font-size: 18px;
			margin-top: 35px;
		}
	}

	&__ctas {
		display: flex;
		flex-wrap: wrap;
		margin-top: 36px;
		gap: 30px;
	}

	&__row {
		display: flex;
		justify-content: space-between;
		gap: 50px;
		position: relative;

		&--1 {
			&.customisable-tib__row--media-left > div, 
			&.customisable-tib__row--media-right > div {
 				@media screen and (min-width: 700px) {
					flex-direction: column;
 					width: 46%;
 				}
 			}

			&.customisable-tib__row--media-full {
				flex-direction: column-reverse;
 			}
		}

		&--media-left {
			flex-direction: column-reverse;

			@media screen and (min-width: 700px) {
				flex-direction: row-reverse;
			}
		}
	}

	&__image,
	&__video {
		position: relative;
		height: 100%;
		width: 100%;

		img,
		video {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}
