$primary_font: 'Noto Serif';
$secondary_font: 'Noto Serif';

$grey1: #dddddd;
$grey2: #f0eeef;
$grey3: #9fa1a4;
$grey4: #54565a;
$white: white;

$btn_bg_colour: $grey3;
$btn_text_colour: $white;

$text_colour: $grey4;

$grid_width: 1280px;
